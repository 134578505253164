import React from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby'; 
import Img from 'gatsby-image';

const LogoContainer = styled.div`
width:250px;
font-size:0.7em;
line-height: 1.2em;
img {
  margin:0 0 10px 0;
}
`;

const Logo = () => {

  const data = useStaticQuery(graphql`
    query logoQuery {
      file(relativePath: { eq: "metro-logo.png" }) {
        childImageSharp {
          fixed(height: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <LogoContainer>
      <Link to={`/`}><Img fixed={data.file.childImageSharp.fixed} alt={`logo`}/></Link>
      
    </LogoContainer>
  );
};

export default Logo;
import styled from 'styled-components';

export const Container = styled.div`
  max-width:1100px;
  margin: 0 auto;
  padding: 50px 20px;
  width:100%;

`;

export const Button = styled.button`
  background-color:white;
  border: 1px solid black;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size:16px;
  border-radius:26px;
  transition: background 0.25s, color 0.25s;
  margin-bottom:1rem;
  cursor: pointer;

  &:hover {
    background: #02899B;
    color: #fff;
    border: 1px solid #02899B;
  }

`;

export const Quote = styled.div`
  border-left: 2px solid #208e9d;
  padding: 15px 30px;
  margin-bottom: 25px;
  color: #02899B;
  font-style: italic;

`;

export const YouTubeVideo = styled.div`
  position: relative;
  padding-bottom: 56.25% /* 16:9 */;
  padding-top: 25px;
  height: 0;
  margin-bottom:25px;

  iframe {
    display:block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
  }
`;

export const Process = styled.p`
  text-align: center;
  font-weight: bold;
`;
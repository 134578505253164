import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Container } from "./Theme"

import footerBackground from "../../static/assets/map-world-1.jpg"

import whiteLogo from "../../static/assets/logo-white.png"
import privacyPolicy from "../downloads/Metro-Communications-Privacy-Policy.pdf"

const FooterWrapper = styled.div`
  background: #1e1e1e;
  background-image: url(${footerBackground});
  background-size: cover;
  background-position: center;
`

const FooterContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  color: #fff;
  margin-bottom: 0;
  a {
    text-decoration: none;
    color: #fff !important;
  }

  a:hover {
    text-decoration: underline;
  }

  ul {
    list-style: none;
  }

  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr 2fr;
  }
`

class Footer extends Component {
  render() {
    const d = new Date()
    const currentYear = d.getFullYear()

    return (
      <FooterWrapper>
        <FooterContainer>
          <div>
            <a href="https://www.metrocomms.co.uk/">
              <img src={whiteLogo} alt={`White Metro Communications logo`} />
            </a>
          </div>
          <div>
            <ul>
              <li>
                <Link to={`/about/`}>About Us</Link>
              </li>
              <li>
                <Link to={`/website-terms-of-use/`}>Website Terms of Use</Link>
              </li>
              <li>
                <a href={privacyPolicy}>Privacy Policy</a>
              </li>
              <li>
                <Link to={`/ethics/`}>Ethics</Link>
              </li>
            </ul>
          </div>
          <div>
            Copyright © {currentYear} Copyright Metro Communications Limited.
            All rights reserved.
          </div>
        </FooterContainer>
      </FooterWrapper>
    )
  }
}

export default Footer

import React, { Component } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"

import microsoftIcon from '../../../static/assets/ms-square.png';
import facebookIcon from '../../../static/assets/facebook-icon.png';
import twitterIcon from '../../../static/assets/twitter-icon.png';
import youtubeIcon from '../../../static/assets/youtube-icon.png';
import linkedinIcon from '../../../static/assets/linkedin-icon.png';
import vimeoIcon from '../../../static/assets/vimeo-icon.png';
import bloggerIcon from '../../../static/assets/blogger.png';
import shopIcon from '../../../static/assets/shop-icon.png';



const TopBarContainer = styled.div`
  text-align: right;
  padding-top:0;
  padding-bottom:0;
  margin-bottom:0;
  display: inline;  
  a{
    color: #fff !important;
  }
  @media(max-width:660px){
    display: none;
  }
`

const SocialIcons = styled.div`
  a {
    padding-left:0.5rem;
  }  

  img{
    margin:0;
    width: 26px;
    height: 26px;
  }
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  @media( min-width:350px){
    grid-template-columns: 1fr auto;
  }
  


`

class TopBar extends Component {
  render() {
    return (
      <TopBarContainer>
        <SocialIcons>
          <div id={`phone`}>
              <FontAwesomeIcon icon={faPhone} /> 0203 906 1400{" "}
          </div>
          <div>
            <a href="https://www.metrowebshop.co.uk" target="_blank" rel="noopener noreferrer">
              <img src={shopIcon} alt={`Shop Icon`}/>
            </a>
            <a href="https://twitter.com/metrocommsuk" target="_blank" rel="noopener noreferrer">
              <img src={twitterIcon} alt={`Twitter Icon`}/>
            </a>
            <a href="https://www.linkedin.com/company/metrocommsuk" target="_blank" rel="noopener noreferrer">
              <img src={linkedinIcon} alt={`LinkedIn Icon`}/>
            </a>
            <a href="https://www.facebook.com/metrocommsuk/" target="_blank" rel="noopener noreferrer">
              <img src={facebookIcon} alt={`Facebook Icon`}/>
            </a>
            {/* <a href="https://www.youtube.com/channel/UCpLK5nvcw7SaINYFaBLoOWQ" target="_blank" rel="noopener noreferrer">
              <img src={youtubeIcon} alt={`YouTube Icon`}/>
            </a> */}
            {/* <a href="https://vimeo.com/metrocommsuk" target="_blank" rel="noopener noreferrer">
              <img src={vimeoIcon} alt={`Vimeo Icon`}/>
            </a> */}
            <a href="https://metrocommsuk.blogspot.com" target="_blank" rel="noopener noreferrer">
              <img src={bloggerIcon} alt={`Blogger Icon`}/>
            </a>
            <a href="https://appsource.microsoft.com/en-us/marketplace/partner-dir/10175ff3-99b5-4aac-8f75-7efa8356b7e4/overview" target="_blank" rel="noopener noreferrer">
              <img src={microsoftIcon} alt={`Microsoft Icon`}/>
            </a>
          </div>
        </SocialIcons>


      </TopBarContainer>
    )
  }
}

export default TopBar

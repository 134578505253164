import React, { Component } from 'react';
import styled from 'styled-components';
import TopBar from './navigation/TopBar';
import Logo from './Logo';
// import MainMenu from './navigation/MainMenu'
import Menu from './Menu/Menu'


const HeaderContainer = styled.header`
  max-width:1100px;
  margin: 0 auto;
  padding: 10px 20px;
  width:100%;
  display:grid;
  grid-template-columns: 250px auto;
`;

const NavWrapper = styled.div`
  justify-self: end;
`;

class Header extends Component {
  render() {
    return (
      <HeaderContainer>
        <Logo />
        <NavWrapper>
          <TopBar />
          <Menu/>
        </NavWrapper>
      </HeaderContainer>
    );
  }
};

export default Header;
import React from "react"
import PropTypes from "prop-types"
import Helmet from 'react-helmet'
import 'typeface-roboto';
import styled from 'styled-components';


// Custom components
import Header from './Header.js';
import Footer from './Footer.js';
import "./layout.css"
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const LayoutWrapper = styled.div`
font-size:0.85em;
font-family: roboto, sans-serif;
h1, h2, h3, h4 {
  text-transform: uppercase;
  font-weight: normal;
}
a {
  color: #02899B;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
`;



const Layout = ({ children }) => (
  <LayoutWrapper>
    <Helmet
        meta={[
          { name: 'viewport', content: `width=device-width, initial-scale=1.0` },
        ]}
      />
    
    <Header />
    <main>{children}</main>
    <Footer />
  </LayoutWrapper>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React, { useState } from 'react'
import _ from 'lodash' 
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import menuButton from '../../../static/assets/menu-button.png'


const MenuButton = styled.button`
float: right;
display: flex;
flex-direction: column;
justify-content: space-between;
background: transparent;
border: none;
padding: 0;
height: 30px;
width: 36px;
cursor: pointer;
box-sizing: border-box;
margin-top: 1rem;
div {
  width: 36px;
  height: 5px;
  background: #222;
}
`

const MenuContainer = styled.div`
div {
  background: #005e6b;
  padding: 1.5em;
  box-shadow: -5px 2px 10px -4px rgba(0,0,0,0.5);
  font-size: 1.15em;
  width: 600px;
  max-width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  right: 0;
  z-index: 200;
  button {
    background: none;
    color: #fff;
    border: 2px solid #fff;
    margin-bottom: 2rem;
  }
}
`

const MenuContent = styled.div`
height: 100%;
overflow: auto;

ul {
  list-style: none;
  margin-left: 0;
  li {
    font-size: 1.3rem;
    text-transform: uppercase;
  }
}
ul ul {
  margin-left: 1.2rem;
  li {
    font-size: 1rem;
    
  }
}
ul ul ul {
  li {
    font-size: 1rem;
  }
}
li a {
  color: #fff;
  line-height: 4rem;
}
`


const Menu = () => {

  const data = useStaticQuery(graphql`
  query menuQuery {
    allMarkdownRemark(filter: {frontmatter: {type: {in: ["subpage", "service"]}}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            type
            title
            parent
          }
        }
      }
    }
  }
  `)

  const [isToggled, setToggled] = useState(false);
  const slide = useSpring({
    transform: isToggled ? 'translateX(0)' : 'translateX(+100%)'
  })

  const returnChildren = parent => {

    let children = []

    data.allMarkdownRemark.edges.forEach(({node}) => {
      if(node.frontmatter.parent === parent)
      children.push({
        title: node.frontmatter.title,
        slug: node.fields.slug,
        type: node.frontmatter.type,
      });
    })

    children = _.orderBy(children, 'title')

    return children

  }

  const buildSubMenu = parent => {

    let pages = returnChildren(parent);
    let i = 0;

    pages.forEach(page => {
      pages[i].children = returnChildren(page.title);
      i++;
    })
    
    if(pages){
      return(<ul>
      { pages.map(page => (
        <li className={page.type}><Link to={page.slug}>{page.title}</Link>
          {
            page.children && 
          <ul>
            {page.children.map(child => (
              <li className={page.type}><Link to={child.slug}>{child.title}</Link></li>
              ))}
          </ul>
            }
        </li>
      )) }
    </ul>)
    }
    
  }

  return (
    <div>

      <MenuButton onClick={() => (setToggled(!isToggled))}>
        <img src={menuButton} alt={`Menu button`} />
      </MenuButton>
      <MenuContainer>
        <animated.div style={slide}>
          <MenuContent>
            <button onClick={() => (setToggled(false))}>X</button>
            <ul>
              <li><Link to={`/your-challenges/`}>Your challenges</Link>
                {buildSubMenu('Your challenges')}
              </li>
              <li><Link to={`/solutions/`}>Solutions</Link>
                {buildSubMenu('Solutions')}
              </li>
              <li><Link to={`/services/`}>Services</Link>
                {buildSubMenu('Services')}
              </li>
              <li><Link to={`/industries/`}>Industries</Link>
                {buildSubMenu('Industries')}
              </li>
              <li><Link to={`/news/`}>News &amp; Blog</Link></li>
              <li><Link to={`/contact-us/`}>Contact us</Link></li>
            </ul>
          </MenuContent>
        </animated.div>
      </MenuContainer>
    </div>
  )
}

export default Menu
